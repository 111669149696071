
::v-deep .customTable td:first-child>.cell {
    padding-left: 25px;
}

::v-deep .customTable th:first-child>.cell {
    padding-left: 25px;
}

/deep/.pages-center {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.goods-container {
    height: calc(100vh - 140px);
    display: flex;

    .script-box {
        width: 600px;
        height: 100%;
        margin-right: 20px;
    }

    .goods-table {
        width: 100%;
        height: 100%;
        flex: 1;
        overflow: hidden;
    }
}

.goodsInfo {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;

    span {
        margin-left: 10px;
    }
}
